export default {
  videos: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  filter: {
    search: null,
    completed: null,
  },

  selectedVideo: {
    id: null,
    tags: [],
    programs: [],
    categories: [],
    training_programs: [],
  },
}
